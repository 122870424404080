import React, {Fragment, useState} from 'react';
import styled from 'styled-components';
import qs from 'query-string';
import {useOrder} from '../../Hooks/useOrder';
import Spinner from '../../Components/Spinner';
import {Card, FlexRowCenterStart, Text10, Text22} from '../../widget';
import {SectionHeader} from '../Landing/SectionHeader';
import * as L from '../../Utils/Lang';
import {Color, INPUT_TYPES} from '../../constants';
import {BlockSelector} from '../../Components/BlockSelector';
import {useForm} from 'react-hook-form';
import {Input} from '../../Components/Input';
import {Checkbox} from 'antd';
import {RectButton} from '../../Components/RectButton';
import moment from 'moment';
import useModal from '../../Hooks/useModal';
import {useOutlet} from 'reconnect.js';
import {getFormat} from '../../Utils/Price';
import {CancelOrderModalContent} from '../../Components/CancelOrderModalContent';
import {withLoginRequired} from '../../HOC/withLoginRequired';

const CheckoutPage = (props) => {
  const {location} = props;
  const {id} = qs.parse(location.search);
  const [actions] = useOutlet('actions');
  const {order, isLoading} = useOrder(id);
  const invoiceForm = useForm();
  const [isChecked, setIsChecked] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const {modalRef, modalElem} = useModal();
  const [user] = useOutlet('user');

  const EMAIL_FIELD = {
    placeholder: '請輸入電子信箱',
    name: 'email',
    defaultValue: user.email,
    rules: {
      required: true,
      pattern: /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/,
    },
    message: {
      required: '電子信箱不可為空',
      pattern: '信箱格式不正確',
    },
    label: '電子信箱',
    type: INPUT_TYPES.text,
  };

  const onCancelOrder = () => {
    modalRef.current.open();
    modalRef.current.set({
      elem: <CancelOrderModalContent id={id} modalRef={modalRef} />,
    });
  };

  const onCheckout = async (data) => {
    const payload = {
      id: id,
      buyer_email: data.email,
    };
    const redirectUrl = await actions.checkout(payload);
    window.open(redirectUrl, '_self');
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Wrapper>
      <Card style={{marginBottom: 10}}>
        <SectionHeader title="購買資訊" caption="recipe" />
        <div className="dashed-sep" />
        {order.isFilmOrder && (
          <Fragment>
            <div>影城： {order.films_info[0].cinemaName}</div>
            <div>
              電影：{' '}
              {L.d({
                zh: order.films_info[0].titleAlt,
                en: order.films_info[0].title,
              })}
            </div>
            <div>
              場次：{' '}
              {moment(order.sessions[0].showDateTime).format(
                'YYYY/MM/DD HH:mm',
              )}
            </div>
          </Fragment>
        )}
        {order.isFilmOrder &&
          order.sessions[0].aggregateTickets.map((t, i) => (
            <Fragment key={i}>
              <div className="dashed-sep" />
              <div className="ticket-property">
                <div className="name">
                  {L.d({zh: t.altDescription, en: t.description})}
                </div>
                <div className="quantity">x{t.quantity}</div>
                <div className="price">
                  {getFormat(t.quantity * t.priceCents)}
                </div>
              </div>
            </Fragment>
          ))}

        {order.aggregateConcessions.map((c, i) => {
          const targetConcession = order.concessions_info.find(
            (ci) => ci.id === c.id,
          );
          return (
            <div key={i} className="ticket-property">
              <div className="name">{targetConcession.title}</div>
              <div className="quantity">x{c.quantity}</div>
              <div className="price">
                {getFormat(c.quantity * c.finalPriceCents)}
              </div>
            </div>
          );
        })}

        <div className="ticket-property">
          <div className="name">手續費</div>
          <div className="price">
            {getFormat(order.vista_order.BookingFeeValueCents)}
          </div>
        </div>
        <div className="dashed-sep" />
        <div className="ticket-property">
          <div className="name">總計</div>
          <Text22
            style={{
              color: Color.orange,
              fontWeight: 'bold',
              width: '100%',
              textAlign: 'right',
            }}>
            {getFormat(order.vista_order.TotalValueCents)}
          </Text22>
        </div>
      </Card>
      <Card style={{marginBottom: 10}}>
        <SectionHeader
          title="發票資訊"
          caption="invoice"
          style={{marginBottom: 20}}
        />
        <Input
          errors={invoiceForm.formState.errors}
          field={EMAIL_FIELD}
          register={invoiceForm.register(EMAIL_FIELD.name, EMAIL_FIELD.rules)}
        />
      </Card>
      <Card
        style={{marginBottom: 50}}
        clickable
        onClick={() => {
          setCollapsed(!collapsed);
        }}>
        <SectionHeader title="線上付款政策" caption="policy" />
        {!collapsed && (
          <Fragment>
            <div className="dashed-sep" />
            <div>
              1.「團體優待票券/愛心票/敬老票」無法與「一般/銀行優惠/iShow會員票種」同時訂票，請分次訂購。
              EX：要訂1張團體優待票券與1張全票。須於『團體優待票券/愛心票/敬老票』訂票系統先訂團體優待票券後，再使用『一般/銀行優惠/iShow會員票種.線上即時付款』訂票系統訂全票，唯兩筆訂票無法保證座位。
              2.銀行優惠票種與iShow會員票種無法於同筆訂單中，請分次訂購，唯兩筆訂票無法保證座位。\u20283.銀行購票優惠即日起可於網路訂票系統進行預訂。網路預訂以電影播放日期為準，而非以刷卡日計算，每卡每日購買張數限制依影片類型、單日購票張數相關規定限制。
              4.未滿２歲且不佔位之兒童無需購票可免費入場觀賞【普遍級】影片，每位兒童需由至少一位已購票之成人陪伴。\u20285.需佔位或２歲以上未滿１２歲之兒童；需購買優待票。
            </div>
          </Fragment>
        )}
      </Card>
      <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
        <Checkbox
          style={{
            color: Color.orange,
            outline: Color.orange,
            fontSize: 10,
            fontWeight: 'bold',
          }}
          onChange={(e) => {
            const {checked} = e.target;
            setIsChecked(checked);
          }}>
          我同意以上條款
        </Checkbox>
      </div>
      <Text10
        style={{
          fontWeight: 'bold',
          marginBottom: 10,
          color: Color.orange,
          width: '100%',
          textAlign: 'center',
        }}>
        ＊＊影城絕不會要求您操作ATM或是 取消分期付款相關事項＊＊
      </Text10>
      <RectButton
        disabled={!isChecked}
        onClick={invoiceForm.handleSubmit(onCheckout)}
        style={{width: '100%', marginBottom: 10}}>
        信用卡付款
      </RectButton>
      <RectButton
        onClick={onCancelOrder}
        style={{
          width: '100%',
          backgroundColor: Color.black_10,
          color: Color.black_50,
        }}>
        取消付款
      </RectButton>
      {modalElem}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  max-width: var(--contentMaxWith);
  padding: var(--basePadding);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--topNavBarHeight));
  & .dashed-sep {
    height: 1px;
    width: 100%;
    border-bottom: 1px dashed ${Color.black_50};
    margin: 10px 0;
  }
  & .ticket-property {
    width: 100%;
    ${FlexRowCenterStart};
    text-align: left;
    & > .name {
      min-width: 200px;
    }
    & > .price {
      text-align: right;
      width: 100%;
    }
`;

export default withLoginRequired(CheckoutPage);

import {useOutlet} from 'reconnect.js';
import React, {useState} from 'react';
import {navigate} from 'gatsby-link';
import {ConfirmModalContent} from './ConfirmModalContent';

export function CancelOrderModalContent(props) {
  const {id, modalRef} = props;
  const [actions] = useOutlet('actions');
  const [isFetching, setIsFetching] = useState(false);
  const onCancelOrder = async () => {
    try {
      setIsFetching(true);
      await actions.cancelOrder(id);
      modalRef.current.close();
      await navigate('/wallet');
    } catch (e) {
    } finally {
      setIsFetching(false);
    }
  };
  return (
    <ConfirmModalContent
      modalRef={modalRef}
      isLoading={isFetching}
      title="取消此訂單"
      subtitle="確定要取消此訂單嗎？"
      onConfirm={onCancelOrder}
      onCancel={() => {
        modalRef.current.close();
      }}
    />
  );
}

export function CancelUnpaidOrderModalContent(props) {
  const {id, modalRef} = props;
  const [actions] = useOutlet('actions');
  const [isFetching, setIsFetching] = useState(false);
  const onCancelOrder = async () => {
    try {
      setIsFetching(true);
      const payload = {
        CinemaId: '1001',
        order_id: id,
      };
      await actions.cancelUnpaidOrder(payload);
      modalRef.current.close();
      await navigate('/wallet');
    } catch (e) {
    } finally {
      setIsFetching(false);
    }
  };
  return (
    <ConfirmModalContent
      modalRef={modalRef}
      isLoading={isFetching}
      title="取消此訂單"
      subtitle="確定要取消此訂單嗎？"
      onConfirm={onCancelOrder}
      onCancel={() => {
        modalRef.current.close();
      }}
    />
  );
}

import React from 'react';
import {useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import {FlexColumnCenterCenter} from '../widget';
import {SectionHeader} from '../Templates/Landing/SectionHeader';
import {navigate} from 'gatsby';
import {RectButton} from '../Components/RectButton';

export const withLoginRequired = (NextComp) => {
  return (props) => {
    const [user] = useOutlet('user');
    if (!user) {
      return (
        <Wrapper>
          <div className="view">
            <SectionHeader
              style={{marginBottom: 60}}
              title="使用前需先登入"
              caption="login required"
              align="center"
            />
            <RectButton
              style={{width: '100%'}}
              onClick={async () => {
                await navigate('/login');
              }}>
              會員登入
            </RectButton>
          </div>
        </Wrapper>
      );
    }
    return <NextComp {...props} />;
  };
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--topNavBarHeight));
  margin: var(--topNavBarHeight) auto 0 auto;
  align-items: center;
  & > .view {
    flex: 1;
    ${FlexColumnCenterCenter};
    width: 100%;
    max-width: var(--contentMaxWith);
    padding: var(--basePadding);
  }
`;
